import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    set_email() {
        const commercial_contact_name = this.element.value;

        const commercial_contact_db = [
            { name: "Edmar Rodrigues", email: "edmar@sigagr.com.br" },
            { name: "Evelyn Martins", email: "evelyn.martins@sigagr.com.br" },
            { name: "Jackson Froguel", email: "jackson@sigagr.com.br" },
            { name: "Rubion Scher", email: "rubion.scher@gmail.com" },
        ]

        const commercial_contact_found = commercial_contact_db.find(contact => contact.name === commercial_contact_name)
        const emailField = document.getElementById('customer_contact_email');
        emailField.value = commercial_contact_found.email;
    }
}